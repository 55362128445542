export const TOKENT_KEY_NAME = 'rysebet-token';
export const DEFAULT_CURRENCY = 'UAH';
export const DEFAULT_TIMEZONE = 3;
export const DEFAULT_LANG = 'uk';
export const AVAILABLE_LANGS = ['ru', 'uk', 'en', 'kk', 'az', 'uz', 'da', 'es', 'pt'];
export const ONLY_CURRENCY: string | null = null;
export const EXCLUDED_CURRENCY: string[] | null = ['FUN', 'DEMO', 'BUSD', 'TZS', 'KES'];
export const AVAILABLE_THEMES = ['light-blue'];
export const DEFAULT_THEME = 'light-blue';
export const COMPANY_NAME = 'Rysebet';
export const SUPPORT_LINK = 'https://t.me/rysebet_operator';
export const SOCIAL_LINK_TG = 'https://t.me/rysebett';
export const SOCIAL_LINK_INST = 'https://www.instagram.com/rysebet/';
export const SOCIAL_LINK_VK = '';
export const SOCIAL_LINK_FACEBOOK = '';
export const SOCIAL_LINK_TWITTER_X = '';
export const SOCIAL_LINK_TIKTOK = '';
export const LOGO_BOOMAKER_RATINGS = '#';
export const ANDROID_APP = '/rysebet.apk';
export const EXCLUDE_CASINO_CATEGORY = ['gameslobby', 'roshambo', 'arcade', 'moon', 'jetx'];
export const PASSWORD_MIN_LENGTH = 8;

export const DEFAULT_LOGOTYPE = '/logotype.svg';
export const MainLogotype = {
  DARK_PURPLE: '/logotype.svg',
  BLUE: '/logotype.svg',
  DARK_BLUE: '/logotype.svg',
  LIGHT_BLUE: '/logotype.svg',
};
export const CouponLogotype = {
  DARK_PURPLE: '/logotype.svg',
  BLUE: '/logotype.svg',
  DARK_BLUE: '/logotype.svg',
  LIGHT_BLUE: '/logotype.svg',
};

export const AppFeature = {
  DIGITAIN_GAMES: false,
  BONUS_AND_PROMO: true,
  REGISTRATION_TELEGRAM: false,
  REGISTRATION_PHONE: false,
  KYC_VERIFICATION: false,
  ADAPTIVE_BANNERS: false,
  KES_HOME_PAGE: false,
  KES_PROJECT: false,
  KES_TAXES: false,
  DISABLE_SPORTBOOK: false,
  CASINO_ONLY: false,
};

if (ONLY_CURRENCY && EXCLUDED_CURRENCY) {
  if (EXCLUDED_CURRENCY.includes(ONLY_CURRENCY)) {
    throw new Error('EXCLUDED_CURRENCY contains the ONLY_CURRENCY value');
  }
}
